<template>
  <div class="password">
    <div class="header">
      <img src="@/assets/img_01.png" alt="" class="logo" />
      <span>找回密码</span>
    </div>
    <div class="form-wrapper">
      <p class="title">请填写您需要找回的账号</p>
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="code-wrapper">
            <el-input
              v-model="form.code"
              placeholder="请输入4位短信验证码"
            ></el-input>
            <div class="send-btn" @click="send">{{ sendTxt }}</div>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            show-password
            v-model="form.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="login-btn" @click="submit">确认</div>
    </div>
    <!-- <div class="form-wrapper">
      <p class="title">请填写新密码</p>
      <el-form ref="form" :model="form" label-width="0px">
        <el-form-item label="">
          <el-input v-model="form.name" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.name" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="login-btn">确认</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "password",
  data() {
    return {
      form: {
        phone: "",
        password: "",
        code: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 12, message: "长度在 6 到 12 个字", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入4位短信验证码", trigger: "blur" },
        ],
      },
      sendTxt: "发送验证码",
      timer: null,
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    send() {
      if (this.form.phone) {
        if (this.sendTxt == "发送验证码") {
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.$api.sms({ phone: this.form.phone }).then();
          this.sendTxt = 60;
          this.timer = setInterval(() => {
            if (this.sendTxt == 1) {
              clearInterval(this.timer);
              this.timer = null;
              this.sendTxt = "发送验证码";
            } else {
              this.sendTxt--;
            }
          }, 1000);
        }
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号码",
          type: "error",
          duration: 1000,
        });
      }
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$api.renew_password(this.form).then((res) => {
            if (res.code == 200) {
              // localStorage.setItem("_token_", res.data.token);
              // this.$router.push({
              //   path: "/",
              // });
              this.$message({
                type: "success",
                message: "修改成功，请登录",
                showClose: true,
                duration: 1000,
              });
              this.$router.push({
                path: "/login",
              });
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1000,
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.password {
  .el-input--medium .el-input__inner {
    background: #f2f3f5;
    &::placeholder {
      color: #868e98;
    }
  }
  .code-wrapper {
    .el-input {
      width: 194px;
    }
  }
}
</style>
<style lang="scss" scoped>
.password {
  height: 100vh;
  background: #fff;
  .header {
    height: 100px;
    display: flex;
    align-items: center;
    background: #2d8cf0;
    padding-left: 50px;
    .logo {
      margin-right: 15px;
    }
    span {
      font-size: 22px;
      color: #fff;
      border-left: 2px solid #fff;
      padding-left: 15px;
    }
  }
  .form-wrapper {
    padding-top: 200px;
    width: 332px;
    margin: 0 auto;
    .title {
      font-size: 16px;
      color: #000;
      line-height: 1.5;
      margin-bottom: 16px;
      font-weight: 600;
    }
    .code-wrapper {
      display: flex;
      .send-btn {
        line-height: 32px;
        width: 124px;
        text-align: center;
        border: 1px solid #2d8cf0;
        color: #2d8cf0;
        font-size: 14px;
        border-radius: 3px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    .login-btn {
      line-height: 38px;
      text-align: center;
      background: #2d8cf0;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      border-radius: 2px;
      margin-bottom: 8px;
    }
  }
}
</style>